import React, { useRef, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import Hls from 'hls.js';
import s from './VideoHero.module.scss';

const propTypes = {
  src: PropTypes.string.isRequired,
  onVideoError: PropTypes.func,
};

const VideoHero = ({ src, onVideoError = () => {} }) => {
  const videoRef = useRef(null);

  const handleVideoError = (e) => {
    onVideoError();
    Bugsnag.notify(e);
  };

  useEffect(() => {
    const playVideo = () => {
      // istanbul ignore next
      videoRef.current.addEventListener('error', (e) => {
        handleVideoError(e);
      });
      videoRef.current.src = src;
      videoRef.current.addEventListener('loadedmetadata', () => {
        try {
          videoRef.current.play();
        } catch (e) {
          // istanbul ignore next
          handleVideoError(e);
        }
      });
    };

    if (Hls.isSupported() && videoRef) {
      if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        playVideo();
      } else {
        const hls = new Hls({
          enableWorker: false,
        });

        hls.attachMedia(videoRef.current);
        hls.on(Hls.Events.ERROR, (e) => {
          handleVideoError(e);
        });
        hls.on(Hls.Events.MEDIA_ATTACHED, () => {
          hls.loadSource(src);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            videoRef.current.play();
          });
        });
      }
    } else {
      playVideo();
    }
  }, [videoRef, src]);

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <video className={s.video} ref={videoRef} playsInline loop muted autoPlay />;
};

VideoHero.propTypes = propTypes;
export default VideoHero;
