export const optimizeImageResolution = (images = []) => {
  const srcSet = {};
  images
    .sort((img, nextImg) => img.width - nextImg.width)
    .forEach((img, i) => {
      srcSet[`${i + 1}x`] = img.path;
    });
  return srcSet;
};

export const optimizeImageResolutionSrcSet = (srcSetObj) => {
  let srcSet = '';
  const srcSetKeys = Object.keys(srcSetObj);
  srcSetKeys.forEach((resolution, i) => {
    const path = srcSetObj[resolution];
    srcSet += `${path} ${resolution}${i !== srcSetKeys.length - 1 ? ', ' : ''}`;
  });
  return srcSet;
};

export const optimizeImageSet = (resizeImages) => {
  const srcSetObj = optimizeImageResolution(resizeImages.images);
  const srcSet = optimizeImageResolutionSrcSet(srcSetObj);
  const lowResImg = srcSetObj['1x'];
  return [
    {
      src: srcSet,
    },
    {
      src: lowResImg,
    },
  ];
};

export const heroImageSet = (image, mobileImage) => {
  return [
    {
      src: `${mobileImage || image}?w=576&h=745&fm=jpg&fit=fill 1x, ${mobileImage ||
        image}?w=960&h=1242&fm=jpg&fit=fill 2x`,
      media: '(max-width: 576px)',
      type: 'image/jpeg',
    },
    {
      src: `${image}?w=768&h=788&fm=jpg&fit=fill&q=70 1x, ${image}?w=1536&h=1576&fm=jpg&fit=fill&q=70 2x`,
      media: '(max-width: 768px)',
      type: 'image/jpeg',
    },
    {
      src: `${image}?w=1024&fm=jpg&q=70 1x, ${image}?w=2048&fm=jpg&q=70 2x`,
      media: '(max-width: 1024px)',
      type: 'image/jpeg',
    },
    {
      src: `${image}?w=1440&fm=jpg&q=70 1x, ${image}?w=2880&fm=jpg&q=70 2x`,
      media: '(max-width: 1440px)',
      type: 'image/jpeg',
    },
    {
      src: `${image}?fm=jpg&q=70&w=2560`,
      type: 'image/jpeg',
    },
  ];
};

export const curatedImageSet = (image) => {
  return [
    {
      src: `${image}?w=576&h=576&fit=fill&f=top&fm=jpg 1x, ${image}?w=960&h=960&fit=fill&f=top&fm=jpg 2x`,
      media: '(max-width: 576px)',
      type: 'image/jpeg',
    },
    {
      src: `${image}?w=700&h=700&fit=fill&f=top&fm=jpg 1x, ${image}?w=1400&h=1400&fit=fill&f=top&fm=jpg 2x`,
      type: 'image/jpeg',
    },
    {
      src: `${image}?w=700&h=700&fit=fill&f=top&fm=jpg`,
      type: 'image/jpeg',
    },
  ];
};
